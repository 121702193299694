import React, { useState, useEffect } from "react";
import Select from "react-select";
import { QuotesApi } from "../../../api";
import { Button, Dropdown } from "../../../common";
import { Container } from "../../../utils";

import { useRatesStore } from "./store";

export const Rates = React.memo(({ data, setRates, user, handleChange }) => {
  const columns = ["Service", "Fee", "Account Code"];

  // const { rates, setRates, updateRate } = useRatesStore();
  const updateRates = QuotesApi.useUpdateAdminRates();
  const { data: itemCodes, isLoading: itemCodesLoading } = QuotesApi.useFetchItemCodes();

  const updateRate = (id, name, value) => {
    setRates(data.map((rate) => (rate.id === id ? { ...rate, [name]: Number(value) } : rate)));
    handleChange(data.map((rate) => (rate.id === id ? { ...rate, [name]: Number(value) } : rate)));
  };

  const updateRateString = (id, name, value) => {
    setRates(data.map((rate) => (rate.id === id ? { ...rate, [name]: String(value) } : rate)));
    handleChange(data.map((rate) => (rate.id === id ? { ...rate, [name]: String(value) } : rate)));
  };

  const updateNameRate = (id, value) => {
    setRates(data.map((rate) => (rate.id === id ? { ...rate, service: value } : rate)));
    handleChange(data.map((rate) => (rate.id === id ? { ...rate, service: value } : rate)));
  };

  const getItemCode = (id) => {
    const rate = data.find((rate) => rate.id === id);
    return rate?.item_code;
  };

  const newRate = () => {
    data.push({
      id: data[data.length - 1]?.id + 1,
      service: "New Rate",
      type: "Additional",
      erect_fee: 0,
      hire_fee: 0,
      item_code: "",
      new_rate: true,
    });
    setRates(data);
    handleChange(data);
  };

  const storeRates = () => {
    console.log("Data to be stored", data);
    const dataWithoutNewRate = data.map((rate) => {
      const newRate = {
        id: rate.id,
        service: rate.service,
        type: rate.type,
        erect_fee: rate.erect_fee,
        hire_fee: rate.hire_fee,
        item_code: rate.item_code,
      };
      return newRate;
    });

    updateRates.mutateAsync(dataWithoutNewRate);
  };

  // console.log(data);
  const itemCodeOptions = itemCodes?.map((item) => ({ value: item.code, label: item.code }));

  if (itemCodesLoading) return <div>Loading...</div>;
  // console.log(itemCodeOptions);
  // console.log(itemCodes);

  // const handleTrigger = () => {
  //   handleChange();
  // };
  return (
    <>
      {data ? (
        <div className="w-full">
          <table className="w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr>
                <th className="text-center border w-1/2 border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider">
                  Service
                </th>
                <th className="text-center border w-28 border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider">
                  Fee
                </th>
                <th className="text-center border border-gray-200 px-1 py-2 text-tiny font-medium text-blue-900 uppercase tracking-wider">
                  Item Code
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {data.map((line, index) => (
                <tr key={index}>
                  <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap">
                    <input
                      id={`rateService${line?.id}`}
                      type="text"
                      defaultValue={line?.service}
                      disabled={!line?.new_rate}
                      className={`h-7 rounded-md w-full border border-gray-300 text-gray-900 focus:outline-none${
                        !line?.new_rate ? " bg-gray-100" : ""
                      }`}
                      name="rateService"
                      onChange={(e) => updateNameRate(line?.id, e.target.value)}
                    />
                  </td>
                  <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap">
                    <input
                      id={`rateErect${line?.id}`}
                      type="number"
                      defaultValue={line?.erect_fee}
                      className="h-7 rounded-md w-full border border-gray-300 text-gray-900 focus:outline-none"
                      name="rateErect"
                      onChange={(e) => updateRate(line?.id, "erect_fee", e.target.value)}
                    />
                  </td>
                  <td className="px-2 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap">
                    {/* <input
                      id={`item_code${line?.id}`}
                      type="text"
                      defaultValue={line?.item_code}
                      className="h-7 rounded-md w-full border border-gray-300 text-gray-900 focus:outline-none"
                      name="item_code"
                      onChange={(e) => updateRateString(line?.id, "item_code", e.target.value)}
                    /> */}
                    <Select
                      id={`item_code${line?.id}`}
                      isMulti={false}
                      isLoading={itemCodesLoading}
                      isDisabled={itemCodesLoading}
                      options={itemCodeOptions}
                      // onBlur={() => onBlur(id, true)}
                      onChange={(val) => {
                        console.log("Value: ", val);
                        updateRateString(line?.id, "item_code", val.label);
                      }}
                      value={itemCodeOptions.filter((option) => option.label === line?.item_code)}
                      className="text-xs"
                      // styles={repeatingForm && customStyles}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <div className="flex items-center">
            <Button className="w-1/2" onClick={() => newRate()}>
              Add new rate
            </Button>
            <div className="w-0.5" />
            <Button className="w-1/2" onClick={() => storeRates()}>
              Save
            </Button>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
});
